.backButton {
  align-items: center;
  background: none;
  border: none;
  color: var(--backlink-color, var(--color-midnight-900));
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:hover svg,
  &:focus svg {
    transform: translateX(-0.5rem) rotate(90deg);
  }

  & svg {
    fill: var(--backbutton-icon-color, var(--color-riptide-300));
    height: 2.8rem;
    transform: rotate(90deg);
    transition: transform 0.2s;
    width: 2.8rem;
  }

  &.labelIsWhite {
    color: var(--color-white);
  }
}
