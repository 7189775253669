.container.hasPadding {
  padding-bottom: 4rem;

  @media (--viewport-m) {
    padding-bottom: 4rem;
  }
}

.backLinkContainer {
  margin-bottom: 2.8rem;
}

.buttonContainer {
  margin-top: 2.4rem;
}

.heroWrapper {
  --ratio-desktop: 16/7;
  --ratio-mobile: 1;

  position: relative;
  display: flex;
  align-items: flex-end;
  aspect-ratio: var(--ratio-mobile, 1);
  margin-bottom: 4rem;
  min-height: 35rem;
  max-height: min(65vh, 80rem);

  &:not(.fullWidth) {
    width: calc(100% + var(--max-width-padding-s) * 2);
    margin-left: calc(var(--max-width-padding-s) * -1);
    margin-right: calc(var(--max-width-padding-s) * -1);

    @media (--viewport-s) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.fullWidth > .heroRelativeContent {
    margin-left: calc(var(--max-width-padding-s) * -1);

    @media (--viewport-l) {
      margin-left: calc(var(--max-width-padding-m) * -1);
    }

    @media (--viewport-xl) {
      margin-left: 0;
    }
  }

  @media (--viewport-s) {
    aspect-ratio: var(--ratio-desktop, 16/7);
    min-height: 40rem;
    margin: 0;
    width: 100%;
  }

  & picture {
    background: var(--page-hero-background-color, #ddd);
    overflow: hidden;
    height: 100%;

    & > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.heroCarouselHolder {
  border-radius: 0;
  overflow: hidden;
  position: absolute;
  inset: 0;
  height: 100%;

  @media (--viewport-s) {
    border-radius: var(--hero-border-radius);
  }

  /* hide the indicators and controls when used in pagehero */
  & picture {
    border-radius: 0;
  }

  /* BD 31-05-2023: Dit voelt heel hackywacky en dat is het ook.
  // Binnen de hero gebruiken we de carousel. Standaard wordt daarin de aspect ratio van de video gebruikt.
  // Door meerdere divs 100% hoogte te geven is het gefixt. Tijdtechnisch doen we het nu even zo. */
  & > div,
  & > div > div,
  & > div > div > div,
  & > div > div > div > div {
    height: 100%;
  }
}

.hasNoIndicators {
  /* hide the indicators and controls when used in pagehero */

  & [class*='indicatorsContainer'] {
    display: none;
  }
}

.heroButtonContainer {
  --spacing: var(--max-width-padding-s, 2.4rem);

  position: absolute;
  left: var(--spacing, 2.4rem);
  bottom: 0;
  transform: translateY(50%);
  z-index: 1;
  display: flex;
  gap: var(--spacing, 2.4rem);
  width: calc(100% - var(--spacing, 2.4rem));

  @media (--viewport-s) {
    --spacing: var(--spacing-s, 2.4rem);
  }

  &.hasNoSpacing {
    width: 100%;
    left: 0;
  }
}

.contentGrid {
  display: grid;
  margin-top: 4rem;
  grid-template-columns: minmax(0, 1fr);
  gap: var(--spacing-s, 2.4rem);

  @media (--viewport-l) {
    /* grid-template-areas: ""; */
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.heroRelativeContent {
  position: relative;
  padding: calc(var(--max-width-padding-s) * 2) var(--max-width-padding-s) var(--max-width-padding-s)
    var(--max-width-padding-s);
  color: var(--homepage-hero-text-color, #fff);
  font-weight: var(--homepage-hero-font-weight, bold);
  width: 100%;

  @media (--viewport-l) {
    padding: calc(var(--max-width-padding-m) * 2) var(--max-width-padding-m) var(--max-width-padding-m)
      var(--max-width-padding-m);
  }

  & h1,
  & h1 * {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    color: inherit;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    font-weight: inherit;
  }
}

.relativeContent {
  @media (--viewport-l) {
    grid-column-end: span 2;
  }

  &.fullWidth {
    @media (--viewport-l) {
      grid-column-end: span 3;
    }
  }
}

.highlightedItem {
  background: var(--highlighted-content-background-color, var(--body-background-color));
  position: relative;
  z-index: 1;
  margin-left: calc(var(--max-width-padding-s) * -1);
  margin-right: calc(var(--max-width-padding-s) * -1);
  padding: var(--max-width-padding-m) var(--max-width-padding-s);

  @media (--viewport-s) {
    padding: var(--max-width-padding-m);
    margin-left: calc(var(--max-width-padding-m) * -1);
    margin-right: calc(var(--max-width-padding-m) * -1);
  }

  @media (--viewport-menu) {
    margin-left: 0;
    margin-right: calc(var(--max-width-padding-m) * -1);
    padding: var(--max-width-padding-m);
    align-self: start;
    border-radius: var(--border-radius-l) 0 var(--border-radius-l) var(--border-radius-l);

    &:not(.noMarginTop) {
      margin-top: -8rem;
    }
  }

  & > .buttonContainer {
    margin-top: 1.2em;
    display: flex;
    flex-wrap: wrap;
    gap: 1.2em;

    & > * {
      width: 100%;

      & > span:first-child {
        width: 100%;
        justify-content: center;
      }
    }
  }

  & h3 {
    font-size: var(--font-size-xl);

    @media (--viewport-m) {
      font-size: var(--font-size-3xl);
    }
  }
}

.operatingCompanyContainer {
  padding-bottom: 2rem;
}

.operatingCompanyLabel {
  position: relative;
  height: 3rem;
  color: var(--color-white);
  font-weight: var(--font-weight-medium);

  & img {
    object-fit: contain;
    padding: 0.5rem 1rem;
    width: fit-content !important;
  }
}

@supports (background: -webkit-named-image(i)) {
  .operatingCompanyLabel img {
    width: 100% !important;
  }
}

/* --------------------------------------------NOVAFORM----------------------------------------------------------- */
.novaform {
  border-radius: var(--border-radius-s);
  color: var(--color-white);

  &.lightBackground {
    color: var(--color-rolling-stone-900);
  }
}
